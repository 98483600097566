







































































































































import { Component, Provide, Vue } from 'vue-property-decorator'
import { AppProvider, appProvider } from '../app-providers'
import { walletStore } from '@/stores/wallet-store'
import { Observer } from 'mobx-vue'
import { alertController } from './alert/alert-controller'

@Observer
@Component
export default class extends Vue {
  @Provide() providers: AppProvider = appProvider
  @Provide() walletStore = walletStore
  private readonly SOLLET_WALLET_NAME = 'Sollet'

  copyAddress() {
    navigator.clipboard.writeText(this.walletStore?.account || '')
  }

  connectSolana(wallet) {
    if (wallet === walletStore.selectedWallet) {
      walletStore.disconnectSolana()
    } else if (wallet.name === this.SOLLET_WALLET_NAME) {
      alertController
        .confirm(
          'Warning',
          'The Sollet wallet sometime encounter error when register for whitelist. Are you sure you want to use this wallet?',
          'Continue',
          'Cancel'
        )
        .then((confirm) => {
          if (confirm) walletStore.connectSolana(wallet)
        })
    } else walletStore.connectSolana(wallet)
  }
}
